<template>
  <div class="page-order">
    <InputSearch code="F1" placeholder="Штрихкод" @submit="setGoods" ref="inputCode"/>

    <template v-if="!load && goods.length">
      <table v-show="goods.filter(g => g.collected!==g.pivot.amount).length">
        <tr>
          <th>
            #
          </th>
          <th>
            Наименование
          </th>
          <th>
            Кол.
          </th>
        </tr>
        <tr v-for="(g,i) in goods.filter(g_ => g_.collected!==g_.pivot.amount)" :key="g.id"
            :class="{error:g.collected>0&&g.collected!==g.pivot.amount}">
          <td class="num">
            {{ i + 1 }}
          </td>
          <td>
            <small v-if="!g.barcodes.length">Штрихкод:
              <strong class="text-red">не назначен</strong><br>
            </small>
            <b>[{{ g.code }}]</b> {{ g.name }}<br>
            <a href="#" @click.prevent="image_uri = g.photo">изображение</a>
          </td>
          <td>
            <strong v-if="g.collected!==g.pivot.amount">{{ g.collected }}</strong>
            <strong class="amount" :class="{success:g.collected===g.pivot.amount}">{{ g.pivot.amount }}</strong>
            <small v-if="g.collected!==g.pivot.amount">ост.<br>{{ g.amount }}</small>
            <br><button @click="selectGoods = g">✅</button>
          </td>
        </tr>
      </table>

      <table v-show="goods.filter(g => g.collected===g.pivot.amount).length">
        <tr>
          <th>
            #
          </th>
          <th>
            Наименование
          </th>
          <th>
            Кол.
          </th>
        </tr>
        <tr v-for="(g,i) in goods.filter(g_ => g_.collected===g_.pivot.amount)" :key="g.id" class="success">
          <td class="num">
            {{ i + 1 }}
          </td>
          <td>
            <small v-if="!g.barcodes.length">Штрихкод:
              <strong class="text-red">не назначен</strong><br>
            </small>
            <b>[{{ g.code }}]</b> {{ g.name }}<br>
            <a href="#" @click.prevent="image_uri = g.photo">изображение</a>
          </td>
          <td>
            <strong v-if="g.collected!==g.pivot.amount">{{ g.collected }}</strong>
            <strong class="amount" :class="{success:g.collected===g.pivot.amount}">{{ g.pivot.amount }}</strong>
            <small v-if="g.collected!==g.pivot.amount">ост.<br>{{ g.amount }}</small>
            <br><button @click="selectGoods = g">✅</button>
          </td>
        </tr>
      </table>
    </template>
    <EmptyData v-else-if="!load"/>

    <ModalImage :uri="image_uri" @close="image_uri = ''"/>

    <ModalConfirm :show="showModalError" status="error" @cancel="closeClear">
      <template #title><h3 class="text-red">Такого товара <br>в заказе нет!</h3></template>
      <template #body><p></p></template>
    </ModalConfirm>

    <DrawerGoods :goods="selectGoods"
                 @preview="src => image_uri = src"
                 @plus="selectGoods.collected++"
                 @minus="selectGoods.collected > 0 ? selectGoods.collected-- : 0"
                 @cancel="closeClear"/>

  </div>
</template>

<script>
import InputSearch from "@/components/InputSearch";
import {onMounted, onUnmounted, ref} from "vue";
import {useRoute} from "vue-router"
import EmptyData from "@/components/EmptyData";
import ModalConfirm from "@/components/ModalConfirm";
import DrawerGoods from "@/components/DrawerGoods";
import ModalImage from "@/components/ModalImage";

export default {
  components: {ModalImage, DrawerGoods, ModalConfirm, EmptyData, InputSearch},
  setup(props, {emit}) {
    const route = useRoute()
    emit('meta', {title: 'Заказ №' + route.params.id, back: '/orders'})

    let load = ref('Загрузка...')
    let goods = ref([])
    let image_uri = ref('')

    const getData = async () => {
      load.value = 'Загрузка...'
      let {data} = await axios.get('v1/orders/' + route.params.id)
      data.goods.map(g => g.collected = 0)
      goods.value = data.goods
      load.value = ''
    }
    getData()

    let selectGoods = ref({});
    let showModalError = ref(false)
    const setGoods = async ({search: barcode}) => {
      if (!barcode) {
        selectGoods.value = {}
        return
      }
      let _goods = goods.value.filter(g => g.barcodes.find(b => b.barcode === barcode))[0]
      if(!_goods){
        showModalError.value = true
      } else {
        selectGoods.value = _goods
        selectGoods.value.collected += _goods.barcodes.find(b => b.barcode === barcode).amount
      }
    }

    const inputCode = ref(null)
    const closeClear = () => {
      selectGoods.value = {}
      showModalError.value = false
      inputCode.value.input.value = ''
    }

    const keydown = (event) => {
      if (event.code === 'F13' && inputCode.value.input !== document.activeElement) {
        inputCode.value.input.value = ''
        inputCode.value.input.focus()
      }
    }

    onMounted(() => {
      document.addEventListener('keydown', keydown)
    })

    onUnmounted(() => {
      document.removeEventListener('keydown', keydown)
    })

    return {
      load, goods, getData, image_uri, inputCode,
      showModalError, closeClear,
      setGoods, selectGoods
    }
  }
}
</script>

<style lang="less">
.page-order {
  tr.success td {
    background: #91e991;
  }
  tr.error td {
    background: #eea7a7;
  }

  .amount {
    display: block;
    margin: 10px 0 0;
    color: red;
    &.success {
      color: green;
    }
  }
}
</style>
